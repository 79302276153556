import provider from "./config";

export const criarIntegraçãoShopify = async (payload, token) => {
  try {
    const response = await provider.post('/entidades/cadastro/integracao/shopify', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const buscarLocEscrit = async (idEstab, token) => {
  try {
    const response = await provider.get('/entidades/loja/locescrit', {
      params: { idEstab },
      headers: { Authorization: `Bearer ${token}`}
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

